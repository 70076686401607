/*experience page*/
.experience-page {
    /*color*/
    background: #0A161C;  /* fallback for old browsers */
    background: -webkit-linear-gradient(to right, #0A161C, #0A161C);  /* Chrome 10-25, Safari 5.1-6 */
    background: linear-gradient(to right, #0A161C, #0A161C); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */

    /*covers whole background*/
    height: calc(100vh - 64px);
}
/**/

/*images on experience page*/
.experience-page img {
    max-width: 100%;
    max-height: 100%;
}
/**/

/*individual cells on experience page*/
.cell1 {
    background: #D5E0DD;  /* fallback for old browsers */
    background: -webkit-linear-gradient(to right, #D5E0DD, #D5E0DD);  /* Chrome 10-25, Safari 5.1-6 */
    background: linear-gradient(to right, #D5E0DD, #D5E0DD); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
}

.cell2 {
    background: #28363D;  /* fallback for old browsers */
    background: -webkit-linear-gradient(to right, #28363D, #28363D);  /* Chrome 10-25, Safari 5.1-6 */
    background: linear-gradient(to right, #28363D, #28363D); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
}

.cell3 {
    background: #ABC1C6;  /* fallback for old browsers */
    background: -webkit-linear-gradient(to right, #ABC1C6, #ABC1C6);  /* Chrome 10-25, Safari 5.1-6 */
    background: linear-gradient(to right, #ABC1C6, #ABC1C6); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
}
/**/

/*text*/
.experience-page text {
    font-size: 22px;
    font-family: 'Roboto', sans-serif;
    font-weight: bolder;
    color: black;
}
