/*transition stuff*/
.fade-enter {
    opacity: 0.01;
    z-index: 1;
}

.fade-enter-active {
    opacity: 1;
    transition: opacity 400ms linear 150ms
}

.fade-exit {
    opacity: 1;
}

.fade-exit-active {
    opacity: 0.01;
    transition: opacity 150ms linear;
}
/**/

/*navigation link stuff*/
a.nav-link {
    color: black;
    padding: 1em;
    font-family: 'Open Sans', sans-serif;
    text-decoration: none;
}

a.nav-link:hover {
    transform: scale(1.10);
}

/**/