/*contact me text*/
.contactMe-grid h1 {
    font-size: 45px;
    font-weight: bold;
    font-family: 'Roboto', sans-serif;
    color: black;
}

.contactMe-grid h2 {
    font-size: 25px;
    font-weight: bold;
    font-family: 'Roboto', sans-serif;
    color: #77dd77;
}

.contactMe-grid h3 {
    font-size: 17px;
    font-family: 'Roboto', sans-serif;
    color: black;
}

.contactMe-grid text {
    font-size: 20px;
    font-family: 'Roboto', sans-serif;
    color: black;
}

.contactMe-grid a {
    font-size: 20px;
    font-family: 'Roboto', sans-serif;
    color: #77dd77;
}

.contactMe-grid a:hover {
    color: black;
    text-decoration: none;
}
/**/